
import { mapGetters } from 'vuex';
import LoginForm from '@razlet/sdk/lib/components/asb/auth/form/login';

export default {
  name: 'login-page',
  layout: 'default',
  components: { LoginForm },
  computed: {
    ...mapGetters(['user']),
    back() {
      if (process.client) {
        return this.$route.query?.path ? this.paringRoute(atob(decodeURIComponent(this.$route.query.path))) : '/';
      }
      return '';
    },
    isAuth() {
      return process.client && this.user?.token;
    },
  },
  mounted() {
    this.$bus.$on('redirect-from-login', () => this.successLogin());
  },
  beforeDestroy() {
    this.$bus.$off('redirect-from-login');
  },
  methods: {
    paringRoute(uri) {
      if (uri.startsWith('/login')) {
        const arrayPath = /path=([a-zA-Z0-9%]+)(&|)/.exec(uri);
        if (arrayPath && arrayPath.length > 2) {
          return this.paringRoute(atob(decodeURIComponent(arrayPath[1])));
        }
        return '/';
      }
      return uri;
    },
    successLogin() {
      if (this.$route.query?.path) {
        this.$router.push(this.back);
      } else {
        this.$router.push('/');
      }
    },
  },
};
